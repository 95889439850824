
import { Component, Vue } from "vue-property-decorator"
import { OpenJobTitlesQuery } from "@/gql"

@Component
export default class Roles extends Vue {
  readonly query = OpenJobTitlesQuery

  readonly search = ""
}
